import { Injectable } from '@angular/core';

import { OnAppInit } from '@frontend/vanilla/core';
import { RewardTokensBannerService } from 'packages/sports/common/betslip/modules/reward-tokens/services/reward-tokens-banner.service';

import { BreadcrumbsBoostrapService } from '../breadcrumbs/breadcrumbs-bootstrap';
import { CrmPromotionProviderService } from '../crm-promotion/crm-promotion-provider.service';
import { OddAcceptanceModeFilterService } from '../dont-accept-stake-option-settings/odd-accept-mode-filter.service';
import { NativeEventSubscription } from '../native-alerts/native-event-subscription.service';
import { SchemaMarkupBoostrapService } from '../schema-markup/schema-markup.bootstrap.service';
import { SettingsService } from '../settings/settings.service';
import { SitemapBootstrapService } from '../sitemap/sitemap.bootstrap.service';
import { SubNavigationBootstrapService } from '../sub-navigation/sub-navigation.bootstrap';
import { PerformanceProfilingService } from '../tracking/performance/performance-profiling.service';
import { RealityCheckService } from '../user/services/reality-check.service';
import { SportsClientConfigRefreshService } from '../user/services/sports-client-config-refresh.service';
import { UserServiceWatcher } from '../user/services/user-service-watcher.service';
import { RewardTokenWidgetRegistration } from './../reward-tokens/widget-registration.service';
import { AnimationDisabledService } from './animation-disable.service';
import { BackgroundWorkerManagerService } from './background-worker-manager.service';
import { BottomNavBootstrapService } from './bottom-nav-bootstrap.service';
import { CriticalErrorBootstrapService } from './critical-error-bootstrap.service';
import { PopupInitializationService } from './initialize-popup.service';
import { IntroPagesBootstrapService } from './intro-pages-bootstrap';
import { LaunchDarklyFlagManagerService } from './launch-darkly-flag-manager.service';
import { LayoutBootstrapService } from './layout-bootstrap.service';
import { LazySportsConfigLoaderService } from './lazy-sports-config-loader.service';
import { OrientaionChangeService } from './orientation-change.service';
import { SetFeatureClassesService } from './set-feature-styles.service';
import { SlotLayoutRouteOverrideService } from './slot-layout.bootstrap.service';
import { ToggleBottomNavService } from './toggle-bottom-nav.service';

@Injectable({
    providedIn: 'root',
})
export class AppBootstrapService implements OnAppInit {
    constructor(
        private animationDisabledService: AnimationDisabledService,
        private criticalErrorBootstrapService: CriticalErrorBootstrapService,
        private setFeatureClassesService: SetFeatureClassesService,
        private orientaionChangeService: OrientaionChangeService,
        private layoutBootstrapService: LayoutBootstrapService,
        private sportsClientConfigRefreshService: SportsClientConfigRefreshService,
        private nativeEventSubscription: NativeEventSubscription,
        private popupInitializationService: PopupInitializationService,
        private introPagesBootstrapService: IntroPagesBootstrapService,
        private bottomNavBootstrapService: BottomNavBootstrapService,
        private breadcrumbsBoostrapService: BreadcrumbsBoostrapService,
        private schemaMarkupBoostrapService: SchemaMarkupBoostrapService,
        private subNavigationBootstrapService: SubNavigationBootstrapService,
        private slotLayoutRouteOverrideService: SlotLayoutRouteOverrideService,
        private performanceProfilingService: PerformanceProfilingService,
        private realityCheckService: RealityCheckService,
        private userStateService: UserServiceWatcher,
        private toggleBottomNav: ToggleBottomNavService,
        private rewardTokensBannerService: RewardTokensBannerService,
        private backgroundWorkerManagerService: BackgroundWorkerManagerService,
        private settingsService: SettingsService,
        private rewardTokenWidgetRegistration: RewardTokenWidgetRegistration,
        private sitemapBootstrapService: SitemapBootstrapService,
        private lazySportsloaderService: LazySportsConfigLoaderService,
        private crmPromotionProviderService: CrmPromotionProviderService,
        private oddAcceptanceModeFilterService: OddAcceptanceModeFilterService,
        private launchDarklyFlagManagerService: LaunchDarklyFlagManagerService,
    ) {}

    async onAppInit(): Promise<any> {
        this.crmPromotionProviderService.onAppInit();
        await this.lazySportsloaderService.onAppInit();
        this.slotLayoutRouteOverrideService.onAppInit();
        this.animationDisabledService.onAppInit();
        this.criticalErrorBootstrapService.onAppInit();
        this.setFeatureClassesService.onAppInit();
        this.orientaionChangeService.onAppInit();
        this.performanceProfilingService.onAppInit();
        this.realityCheckService.onAppInit();
        this.layoutBootstrapService.onAppInit(); // Execute after store
        this.sportsClientConfigRefreshService.onAppInit();
        this.nativeEventSubscription.onAppInit();
        this.launchDarklyFlagManagerService.onAppInit();
        this.popupInitializationService.onAppInit();
        this.introPagesBootstrapService.onAppInit();
        this.bottomNavBootstrapService.onAppInit();
        this.breadcrumbsBoostrapService.onAppInit();
        this.schemaMarkupBoostrapService.onAppInit();
        this.subNavigationBootstrapService.onAppInit();
        this.toggleBottomNav.onAppInit();
        this.settingsService.onAppInit();
        this.rewardTokensBannerService.onAppInit();
        this.rewardTokenWidgetRegistration.onAppInit();
        this.sitemapBootstrapService.onAppInit();
        this.userStateService.onAppInit(); // Execute after store and User bootstrap
        // run it last in order to know that everything is created for our background workers.
        this.backgroundWorkerManagerService.onAppInit();
        this.oddAcceptanceModeFilterService.onAppInit();
    }
}
